/* Include your scripts from /scripts here
*
*	e.g. //= include scripts/main.js
*
*/
$(function(){
	$(".mob_menu").click(function(){
		if($('.main-nav').css('display') == 'none'){
			$('body').addClass("open");
		}else{
			$('body').removeClass("open");
		}
	});
	$( "select")
		.focus(function() {
		 	$(this).parent().addClass('is-focussed');
		})
		.blur(function() {
			$(this).parent().removeClass('is-focussed');
		});
});
